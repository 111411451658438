// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-generate-js": () => import("./../../../src/pages/generate.js" /* webpackChunkName: "component---src-pages-generate-js" */),
  "component---src-pages-generateface-js": () => import("./../../../src/pages/generateface.js" /* webpackChunkName: "component---src-pages-generateface-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musings-js": () => import("./../../../src/pages/musings.js" /* webpackChunkName: "component---src-pages-musings-js" */)
}

